@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700);
* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

html, body {
  scroll-behavior: smooth;
  min-height: auto;
}
.rounded-5 {
    background-color: rgba(80, 1, 1, 0.85);
}

.card-header {
    background-color: rgba(51, 1, 1, 0.925);
}

.card-footer {
    background-color: rgba(51, 1, 1, 0.925);
}

.containerEdit {
    padding-top: 3%;
    padding-left: 4%;
    padding-right: 4%;
    height: 100vh;
    width: 100vw;
    background-color: rgb(117, 22, 22);
    background-size: cover;
    -webkit-font-smoothing: antialiased !important
}
/*CSS DO PRELOAD*/

/*PRELOAD RING*/

.lds-ring {
    display: inline-block;
    position: relative;
    width: 40px;
    height: 40px;
}

.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 43px;
    height: 43px;
    margin: 0px;
    border: 8px solid rgb(199, 22, 22);
    border-radius: 50%;
    -webkit-animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
            animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: rgb(199, 22, 22) transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
    -webkit-animation-delay: -0.45s;
            animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
    -webkit-animation-delay: -0.3s;
            animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
    -webkit-animation-delay: -0.15s;
            animation-delay: -0.15s;
}

@-webkit-keyframes lds-ring {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@keyframes lds-ring {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

/*PRELOAD RING-MIN*/

.lds-ring-min {
    display: inline-block;
    position: relative;
    width: 16px;
    height: 16px;
}

.lds-ring-min div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 22px;
    height: 22px;
    margin: 0px;
    border: 5px solid rgb(199, 22, 22);
    border-radius: 50%;
    -webkit-animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
            animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: rgb(199, 22, 22) transparent transparent transparent;
}

.lds-ring-min div:nth-child(1) {
    -webkit-animation-delay: -0.45s;
            animation-delay: -0.45s;
}

.lds-ring-min div:nth-child(2) {
    -webkit-animation-delay: -0.3s;
            animation-delay: -0.3s;
}

.lds-ring-min div:nth-child(3) {
    -webkit-animation-delay: -0.15s;
            animation-delay: -0.15s;
}

@keyframes lds-ring {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}
.rounded-5 {
    background-color: rgba(80, 1, 1, 0.85);
}

.card-header {
    background-color: rgba(51, 1, 1, 0.925);
}

.card-footer {
    background-color: rgba(51, 1, 1, 0.925);
}

.containerEditAssociateNoScroll {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
}

.containerEditAssociate {
    background: rgb(255, 255, 255) url(/static/media/imgFundoPainel.e89510f4.jpg) no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.content {
    padding-top: 2%;
    padding-bottom: 2%;
    padding-left: 4%;
    padding-right: 4%;
    overflow-y: auto;
    flex-grow: 1;
    min-height: 0;
}

.containerForm {
    padding-top: 2%;
    padding-bottom: 2%;
    padding-left: 4%;
    padding-right: 4%;
    background-color: rgba(0, 0, 0, 0.70);
}

h5,
th,
label,
td {
    color: white;
}
.rowPrincipal {
    background-color: #f5f5f5;
    border-radius: 10px;
    border-top-width: 3px;
    border-top-color: rgb(124, 3, 3);
    border-top-style: solid;
    border-bottom-width: 3px;
    border-bottom-color: rgb(124, 3, 3);
    border-bottom-style: solid;
}

.rowContent {
    border-top-width: 1px;
    border-top-color: rgba(145, 145, 145, 0.5);
    border-top-style: solid;
}

.rowFooter {
    border-bottom-width: 1px;
    border-bottom-color: rgba(145, 145, 145, 0.5);
    border-bottom-style: solid;
}

.row span {
    font-size: 12pt;
}

.inputs {
    border-radius: 5px;
    border-style: solid;
    border-width: 0.9px;
    border-color: hsla(0, 0%, 0%, 0.322);
    padding-left: 9px;
    padding-top: 3px;
    padding-bottom: 3px;
    width: 300px;
    color: rgb(24, 24, 24);
    background-color: hsla(134, 79%, 91%, 0.5);
    margin-top: 3px;
    margin-bottom: 3px;
}

.inputsCep {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-right-style: none;
    border-top-style: solid;
    border-bottom-style: solid;
    border-width: 0.9px;
    border-color: hsla(0, 0%, 0%, 0.322);
    padding-left: 9px;
    padding-top: 3px;
    padding-bottom: 3px;
    width: 300px;
    color: rgb(24, 24, 24);
    background-color: hsla(134, 79%, 91%, 0.5);
}

.btnCep {
    border-left-style: none;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-top-style: solid;
    border-bottom-style: solid;
    border-right-style: solid;
    border-width: 0.9px;
    border-color: hsla(0, 0%, 0%, 0.322);
    padding-top: 3px;
    padding-left: 4px;
    padding-right: 8px;
    padding-bottom: 3px;
    color: rgb(124, 3, 3);
    font-size: 12pt;
    background-color: hsla(134, 79%, 91%, 0.5);
    margin-right: -43px;
}

/*
    DEMO STYLE
*/
body {
    font-family: 'Poppins', sans-serif;
    background: #e4e3e3;
    height: 100%;
    padding: 0px;
    margin: 0px;
}

p {
    font-family: 'Poppins', sans-serif;
    font-size: 1.1em;
    font-weight: 300;
    line-height: 1.7em;
    color: #999;
}

a, a:hover, a:focus {
    color: inherit;
    text-decoration: none;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

.navbar {
    padding: 15px 10px;
    background: #fff;
    border: none;
    border-radius: 5px;
    margin-bottom: 40px;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
}

.navbar-btn {
    box-shadow: none;
    outline: none !important;
    border: none;
}

.line {
    width: 100%;
    height: 1px;
    border-bottom: 1px dashed #ddd;
    margin: 40px 0;
}

/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */

.wrapper {
    display: flex;
    width: 100%;
    align-items: stretch;
    -webkit-perspective: 1500px;
            perspective: 1500px;
}

#sidebar {
    min-width: 250px;
    max-width: 250px;
    background: #7e0202;
    color: #fff;
    -webkit-transition: all 0.6s cubic-bezier(0.945, 0.020, 0.270, 0.665);
    transition: all 0.6s cubic-bezier(0.945, 0.020, 0.270, 0.665);
    -webkit-transform-origin: bottom left;
            transform-origin: bottom left;
}

#sidebar.active {
    margin-left: -250px;
    -webkit-transform: rotateY(100deg);
            transform: rotateY(100deg);
}

#sidebar .sidebar-header {
    padding: 20px;
    background: #7e0202;
}

#sidebar ul.components {
    padding: 20px 0;
    border-bottom: 1px solid #570202;
}

#sidebar ul p {
    color: #fff;
    padding: 10px;
}

#sidebar ul li a {
    padding: 10px;
    font-size: 1.1em;
    display: block;
}

#sidebar ul li a:hover {
    color: #7e0202;
    background: #fff;
}

#sidebar ul li.active>a, a[aria-expanded="true"] {
    color: #fff;
    background: #700909;
}

a[data-toggle="collapse"] {
    position: relative;
}

.dropdown-toggle::after {
    display: block;
    position: absolute;
    top: 50%;
    right: 20px;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
}

ul ul a {
    font-size: 0.9em !important;
    padding-left: 30px !important;
    background: #7e0202;
}

ul.CTAs {
    padding: 20px;
}

ul.CTAs a {
    text-align: center;
    font-size: 0.9em !important;
    display: block;
    border-radius: 5px;
    margin-bottom: 5px;
}

a.download {
    background: #fff;
    color: #7e0202;
}

a.article, a.article:hover {
    background: #7e0202 !important;
    color: #fff !important;
}

/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */

#content {
    width: 100%;
    padding: 20px;
    min-height: 100vh;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

#sidebarCollapse {
    width: 40px;
    height: 40px;
    background: rgb(124, 3, 3);
    border-radius: 7px;
    cursor: pointer;
}

#sidebarCollapse span {
    width: 70%;
    height: 2.5px;
    margin: 0 auto;
    display: block;
    background: white;
    -webkit-transition: all 0.8s cubic-bezier(0.810, -0.330, 0.345, 1.375);
    transition: all 0.8s cubic-bezier(0.810, -0.330, 0.345, 1.375);
    -webkit-transition-delay: 0.2s;
            transition-delay: 0.2s;
}

#sidebarCollapse span:first-of-type {
    -webkit-transform: rotate(45deg) translate(2px, 2px);
            transform: rotate(45deg) translate(2px, 2px);
}

#sidebarCollapse span:nth-of-type(2) {
    opacity: 0;
}

#sidebarCollapse span:last-of-type {
    -webkit-transform: rotate(-45deg) translate(1px, -1px);
            transform: rotate(-45deg) translate(1px, -1px);
}

#sidebarCollapse.active span {
    -webkit-transform: none;
            transform: none;
    opacity: 1;
    margin: 5px auto;
}

div.versao {
    font-size: 15px;
    border-radius: 7px;
    padding: 5%;
    background: #a70404 !important;
    color: #fff !important;
}

.bgAncora {
    background-color: #700909;
}

/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */

@media (max-width: 768px) {
    #sidebar {
        margin-left: -250px;
        -webkit-transform: rotateY(90deg);
                transform: rotateY(90deg);
    }
    #sidebar.active {
        margin-left: 0;
        -webkit-transform: none;
                transform: none;
    }
    #sidebarCollapse span:first-of-type, #sidebarCollapse span:nth-of-type(2), #sidebarCollapse span:last-of-type {
        -webkit-transform: none;
                transform: none;
        opacity: 1;
        margin: 5px auto;
    }
    #sidebarCollapse.active span {
        margin: 0 auto;
    }
    #sidebarCollapse.active span:first-of-type {
        -webkit-transform: rotate(45deg) translate(2px, 2px);
                transform: rotate(45deg) translate(2px, 2px);
    }
    #sidebarCollapse.active span:nth-of-type(2) {
        opacity: 0;
    }
    #sidebarCollapse.active span:last-of-type {
        -webkit-transform: rotate(-45deg) translate(1px, -1px);
                transform: rotate(-45deg) translate(1px, -1px);
    }
}

.spanBtn {
    display: flex;
    justify-content: center;
    padding-left: 3px;
    padding-top: 7px;
    font-size: 23px;
    text-decoration: none;
}

.stileBtn {
   
    position: fixed;
    background-color: #700909;
    border-radius: 16px;
    bottom: 14px;
    box-shadow: 3px 3px 5px -1px rgba(0, 0, 0, 0.75);
    color: rgb(255, 255, 255);
    cursor: pointer;
    height: 50px;
    right: 22px;
    width: 50px;
    z-index : 3;
    
 }

 .stileBtn:hover {
     background-color: #ffffff;
     color: #700909;
 } 
